<template>
  <v-container>
    <div>
      <v-card class="mb-5">
        <custom-snackbar ref="snackbar" />
      </v-card>
        <v-toolbar flat class="d-flex flex-row-reverse pr-3 pb-3">
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:activator="{}">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  class="mr-3"
                  v-on="on"
                >
                  <v-icon dark>mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportPdf">
                  <v-list-item-title>Exportar PDF</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportExcel">
                  <v-list-item-title>Exportar Excel</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn color="info" dark outlined @click="openDialog">
              Adicionar Situação da Produção
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar color="info" dark>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar>
            <v-form ref="form" v-model="valid" lazy-validation v-if="showForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <EmpresasSelect
                        v-model="editedItem.empresa_id"
                        svi
                        obrigatorio
                        return-object
                      ></EmpresasSelect>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ProducoesSelect
                        v-model="editedItem.producao_id"
                        :empresa="editedItem.empresa_id"
                        svi
                        obrigatorio
                        return-object
                      ></ProducoesSelect>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Data e Hora Registro"
                        v-model="editedItem.data_cadastro"
                        :value="editedItem.data_cadastro"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model.number="editedItem.situacao_producao"
                        :items="situacao_producao"
                        label="Situação"
                        :rules="situacaoProducaoRule"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="editedItem.justificativa"
                        outlined
                        name="input-7-4"
                        label="Justificativa"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="validate">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
            <v-card-text v-else>
              <div class="text-center p-5">
                <h4 class="mb-4">Aguarde...</h4>
                <v-progress-circular
                  :size="100"
                  :width="2"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="bg-danger text-white">
            <v-card-title class="headline word-breaker">
              <p>Tem certeza que deseja excluir a Situação?</p>
            </v-card-title>

            <v-card-text class="d-flex justify-center">
              <v-progress-circular
                v-show="loadingDelete"
                :size="50"
                :width="6"
                color="white"
                indeterminate
              />
            </v-card-text>

            <v-card-actions v-show="!loadingDelete">
              <v-spacer />
              <v-btn color="white" text outlined large @click="closeDelete">
                Não
              </v-btn>
              <v-btn
                color="white"
                text
                outlined
                large
                @click="deleteItemConfirm"
              >
                Sim
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </div>
    <!--filtros-->
    <FiltroMobile :has-change="pendingSearch" @search="getData">
      <v-col class="col-12 col-md-6 col-lg-2">
        <EmpresasSelect
          v-model="filterSvis"
          svi
          return-object
        ></EmpresasSelect>
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-3">
        <ProducoesSelect
          v-model="filterProducao"
          :empresa="filterSvis"
          svi
          multiple
          return-object
          :disabled="!filterSvis"
        ></ProducoesSelect>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Cadastro" v-model="filterDataCadastro" />
      </v-col>
      <v-col class="col-12 col-md-8 col-lg-3">
        <v-select
          dense
          outlined
          label="Situação"
          item-text="text"
          item-value="value"
          v-model="situacaoFilterValue"
          :items="situacaoOptions"
        />
      </v-col>
    </FiltroMobile>
    <!--datatable-->
    <v-container class="v-card v-sheet theme--light p-0 p-md-3">
      <v-row>
        <v-col>
          <v-data-table
            dense
            sort-by="data_cadastro"
            sort-desc="true"
            show-expand
            :expanded.sync="dataExpanded"
            :headers="headers"
            :items="data"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :page="page"
            :server-items-length="totalItems"
            :page-count="pageCount"
            :options.sync="options"
            :loading="loading"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.data_cadastro`]="{ item }">
              <span>{{ format_date(item.data_cadastro) }}</span>
            </template>

            <template v-slot:[`item.situacao_producao`]="{ item }">
              <custom-label
                :class="getColor(item.situacao_producao)"
                :text="item.situacao_nome"
              />
            </template>
            <template v-slot:expanded-item="{ item }">

                <td colspan="6" class="py-2 align-top">
                <span class="d-flex">Justificativa: <strong>{{ item.justificativa }}</strong></span>
                <span class="d-flex">Cadastrado por: <strong> {{ item.usuario_nome }}</strong></span>
                </td>
            </template>
            <template v-slot:no-data>
              <v-spacer />
              <div>
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                      text="Nenhum registro encontrado"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.word-breaker {
  word-break: break-word;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      filterDataCadastro: '',
      filterSvis: '',
      filterProducao: [],
      situacaoFilterValue: '',
      dataExpanded: [],
      situacaoOptions: [
        { value: '', text: 'Todos' },
        { value: 1, text: 'Funcionando' },
        { value: 2, text: 'Funcionando Parcialmente' },
        { value: 3, text: 'Não Funcionando' },
      ],
      valid: true,
      producaoRule: [(v) => !!v || 'Necessário informar a Empresa'],
      situacaoProducaoRule: [
        (v) => !!v || 'Necessário informar a Situação da Produção',
      ],
      empresaRule: [(v) => !!v || 'Necessário informar a Produção'],
      situacao_producao: [
        { id: 1, nome: 'Funcionando' },
        { id: 2, nome: 'Funcionando Parcialmente' },
        { id: 3, nome: 'Não Funcionando' },
      ],
      empresaFilterValue: '',
      producaoFilterValue: '',
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        empresa_id: 0,
        producao_id: 0,
        justificativa: '',
        data_cadastro: '',
      },
      defaultItem: {
        empresa_id: 0,
        producao_id: 0,
        justificativa: '',
        data_cadastro: '',
      },
      page: 1,
      pageCount: 0,
      options: {},
      totalItems: 0,
      loading: false,
      loadingDelete: false,
      showForm: true,
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'Adicionar Situação da Produção'
        : 'Editar Situação da Produção'
    },
    expanded () {
      return []
    },
    headers () {
      return [
        { text: '', value: 'data-table-expand', class: 'custom-table--header' },
        { text: 'SVI', value: 'svi', class: 'custom-table--header' },
        {
          text: 'Produção',
          value: 'producao_sigla',
          class: 'custom-table--header',
        },
        {
          text: 'Data e Hora Registro',
          value: 'data_cadastro',
          class: 'custom-table--header',
        },
        {
          text: 'Situação',
          value: 'situacao_producao',
          class: 'custom-table--header',
        },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  created () {},
  mounted () {
    this.initialize()

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Situação das Produções' },
    ])
  },
  methods: {
    initialize () {
      this.defaultItem.data_cadastro = new Date().toLocaleString()
      this.editedItem.data_cadastro = new Date().toLocaleString()

      this.filterDataCadastro = this.$stringFormat.dateToISO(
        this.$date.getDateNow(),
      )
    },
    format_date (dataRegistro) {
      const data = new Date(dataRegistro)
      var dateString =
        data.getDate() +
        '/' +
        ('0' + (data.getMonth() + 1)).slice(-2) +
        '/' +
        data.getFullYear() +
        ' ' +
        ('0' + data.getHours()).slice(-2) +
        ':' +
        ('0' + data.getUTCMinutes()).slice(-2)
      return dateString
    },
    empresaFiltro () {
      const empresa = this.editedItem.producao_id.empresa_id
      return empresa
    },
    producoesFiltro () {
      const producoes = this.editedItem.producao_id.producao_id
      return producoes
    },
    getData () {
      if (!this.filterProducao) {
        return
      }

      this.loading = true
      this.pendingSearch = false
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let svi = ''

      if (this.filterSvis) {
        svi = JSON.stringify(this.filterSvis.id)
      }

      const params = {
        page,
        qtde: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        data_cadastro: this.filterDataCadastro,
        producoes: this.filterProducao.filter((v) => typeof v === 'object').map(function (producao) {
          return producao.empresa_id + '-' + producao.producao_id
        }),
        svi,
        situacao_producao: this.situacaoFilterValue || '',
      }

      ApiService.get(
        '/situacao-producao',
        '?' + this.$stringFormat.queryParamsRecursive(params),
      )
        .then((res) => {
          const dados = res.data
          this.loading = false
          this.totalItems = dados.meta.total
          this.pageCount = dados.meta.per_page
          this.data = dados.data
        })
        .catch((error) => {
          this.loading = false
          console.error(error)
        })
    },
    empresaFilter (value) {
      if (
        this.empresaFilterValue === '' ||
        this.empresaFilterValue === 'Todas'
      ) {
        return true
      }

      return parseInt(value) === parseInt(this.empresaFilterValue)
    },
    producaoFilter (value) {
      if (
        this.producaoFilterValue === '' ||
        this.producaoFilterValue === 'Todas'
      ) {
        return true
      }

      return value === this.producaoFilterValue
    },
    getColor (situacao) {
      if (situacao === 1) {
        return 'bg-funcionando'
      }

      if (situacao === 2) {
        return 'bg-funcionandoParcialmente'
      }

      if (situacao === 3) {
        return 'bg-naoFuncionando'
      }
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false

        if (this.editedIndex > -1) {
          ApiService.put(
            `/situacao-producao/${this.editedItem.id}`,
            this.editedItem,
          )
            .then((res) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Sucesso!',
                'Situação atualizada com sucesso',
              )

              this.afterSave()
              this.close()
            })
            .catch((error) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível atualizar a situação da produção',
                'danger',
              )

              console.error('Erro: ', error)
            })
        } else {
          if (this.editedItem.empresa_id) {
            const empresaId = this.empresaFiltro()
            this.editedItem.empresa_id = empresaId
          }

          if (this.editedItem.producao_id) {
            const producaoId = this.producoesFiltro()
            this.editedItem.producao_id = producaoId
          }

          ApiService.post('/situacao-producao', this.editedItem)
            .then((res) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Sucesso!',
                'Situação adicionada com sucesso',
              )

              this.afterSave()
              this.close()
            })
            .catch((error) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível adicionar a Situação da Produção',
                'danger',
              )

              console.error('Erro: ', error)
            })
        }
      }
    },
    afterSave () {
      this.getData()
      this.resetForm()
    },
    resetForm () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }

      this.defaultItem.data_cadastro = new Date().toLocaleString()
      this.editedItem.data_cadastro = new Date().toLocaleString()
      this.editedItem.justificativa = ''
    },
    async editItem (item) {
      this.editedItem.empresa = item.empresa
      this.editedItem.producao_sigla = item.producao_sigla

      this.dialog = true
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.defaultItem.data_cadastro = new Date().toLocaleString()

      this.editedItem.data_cadastro = this.format_date(
        this.editedItem.data_cadastro,
      )
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/situacao-producao/${this.editedItem.id}`)
        .then(() => {
          this.closeDelete()
          this.loadingDelete = false

          this.$refs.snackbar.show('Sucesso!', 'Situação removida com sucesso')

          this.afterSave()
        })
        .catch((error) => {
          this.loadingDelete = false

          this.$refs.snackbar.show(
            'Erro',
            'Não foi possível remover a Situação',
            'danger',
          )

          console.error('Erro: ', error)
          this.closeDelete()
        })
    },
    openDialog () {
      if (this.$refs.form !== undefined) {
        this.resetForm()
      }

      this.editedItem = this.defaultItem
      this.dialog = true
    },
    close () {
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    async exportPdf () {
      if (this.data.length > 0) {
        this.$refs.snackbar.show(
          'Gerando o arquivo',
          'Aguarde alguns segundos para baixar o arquivo',
          'success',
          -1,
          true,
        )

        let svi = ''

        if (this.filterSvis) {
          svi = JSON.stringify(this.filterSvis.id)
        }

        let param = `?svi=${svi || ''}`
        param += `&empresa_id=${this.$array.pluck(this.filterProducao, 'empresa_id').filter((v) => v !== '').toString() ? this.$array.pluck(this.filterProducao, 'empresa_id').filter((v) => v !== '').toString() : ''}`
        param += `&producao_id=${this.$array.pluck(this.filterProducao, 'producao_id').filter((v) => v !== '').toString() ? this.$array.pluck(this.filterProducao, 'producao_id').filter((v) => v !== '').toString() : ''}`
        param += `&data_cadastro=${this.filterDataCadastro}`
        param += `&situacao_producao=${this.situacaoFilterValue.toString() ? this.situacaoFilterValue.toString() : ''}`
        param += `&sortDesc=${true}`
        param += `&sortBy=${'data_cadastro'}`

        ApiService.get('/reports/situacao-producoes', param)
          .then((res) => {
            if (res.status === 200) {
              this.$refs.snackbar.show('Relatório Gerado', '', 'success', 3000, false)
              const newWindow = window.open()
              newWindow.document.write(res.data)
            }
          })
          .catch((error) => {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível gerar o arquivo, tente novamente mais tarde.',
              'danger',
            )

            console.error('Erro: ', error)
          })
      } else {
        this.$refs.snackbar.show(
          '',
          'Não foram encontrados dados para exportação',
          'alert',
        )
      }
    },
    async exportExcel () {
      if (this.data.length > 0) {
        const url = '/relatorios/exportar'
        let svi = ''

        if (this.filterSvis) {
          svi = JSON.stringify(this.filterSvis.id)
        }

        const filtros = {
          svi: svi || 0,
          empresa_id: this.$array.pluck(this.filterProducao, 'empresa_id').filter((v) => v !== '').toString() ? this.$array.pluck(this.filterProducao, 'empresa_id').filter((v) => v !== '').toString() : '0',
          producao_id: this.$array.pluck(this.filterProducao, 'producao_id').filter((v) => v !== '').toString() ? this.$array.pluck(this.filterProducao, 'producao_id').filter((v) => v !== '').toString() : '0',
          data_cadastro: this.filterDataCadastro,
          situacao_producao: this.situacaoFilterValue.toString() || '',
          sortBy: 'data_cadastro',
        }
        const params = {
          relatorio: 'SituacaoProducao',
          tipo: 'Excel',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },
  },
}
</script>
