import { render, staticRenderFns } from "./SituacaoProducoes.vue?vue&type=template&id=6d19017a&scoped=true&"
import script from "./SituacaoProducoes.vue?vue&type=script&lang=js&"
export * from "./SituacaoProducoes.vue?vue&type=script&lang=js&"
import style0 from "./SituacaoProducoes.vue?vue&type=style&index=0&id=6d19017a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d19017a",
  null
  
)

export default component.exports